const LOGINS = {
  CLIENTS: {
      rutters: [
          'rob.malkus@rutters.com',
          'adam.hirt@rutters.com',
          'brandon.langeheine@rutters.com',
          'nathanial.littles@rutters.com',
          'gregg.miller@rutters.com',
          'joseph.strittmatter@rutters.com',
          'rutters-support'
      ],
      sheetz: [
          'sheetz-support',
          'sheetz-storesolutions'
      ]
  },
  USERS: [
      'cubbys-support',
      'englefield-support',
      'friendship-support',
      'weigels-support'
  ]
};

function findUserLogin(username) {
    // Check USERS first since it's simpler
    if (LOGINS.USERS.includes(username)) {
        // Extract the client name by splitting on '-support'
        const subClient = username.split('-support')[0];
        return { category: 'USERS', client: 'open', subClient: subClient };
    }

    // If not in USERS, look under CLIENTS
    for (let client in LOGINS.CLIENTS) {
        if (LOGINS.CLIENTS[client].includes(username)) {
            return { category: 'CLIENTS', client: client };
        }
    }

    // If username is not found in either category
    return false;
}

const config = {
    OFFLINE_TIMEOUT: 30 * 60 * 1000, // 30 minutes
    WARNING_TIMEOUT: 15 * 60 * 1000, // 15 minutes
    SCREENSHOT_INTERVAL: 5 * 60 * 1000, // 5 minutes

    findUserLogin
};

export default config;